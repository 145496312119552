/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
    font-family: L-Bold;
    src: url(/assets/fonts/Lexend-Bold.ttf);
}

@font-face {
    font-family: L-ExtraBold;
    src: url(/assets/fonts/Lexend-ExtraBold.ttf);
}

@font-face {
    font-family: L-Medium;
    src: url(/assets/fonts/Lexend-Medium.ttf);
}

@font-face {
    font-family: L-Regular;
    src: url(/assets/fonts/Lexend-Regular.ttf);
}

@font-face {
    font-family: L-SemiBold;
    src: url(/assets/fonts/Lexend-SemiBold.ttf);
}

@font-face {
    font-family: L-Light;
    src: url(/assets/fonts/Lexend-Light.ttf);
}

html.ios,
html,
ion-app,
p,
span {
    font-family: L-Regular;
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-title {
    font-family: L-Regular;

}

ion-router-link {
    font-size: 14px;
    font-family: L-Regular;
    color: var(--ion-color-primary);
    font-weight: bold;
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-30 {
    margin-top: 30px;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.logo-container {
    background: var(--header-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    border-radius: 0px 0px 30px 30px;

    img {
        width: 250px;
    }
}

.page-title {
    margin: 0;
    font-size: 22px;
    color: var(--color-black);
    font-family: L-ExtraBold !important;

    @media screen and (max-width:360px) {
        font-size: 18px;
    }
}

.page-dec {
    font-size: 14px;
    color: var(--color-light-black);
    font-family: L-Light !important;
    letter-spacing: 0.7px;
    font-weight: 300;
}

.search-box {
    --background: var(--field-bg);
    --background-activated: var(--field-bg);
    --background-hover: var(--field-bg);
    --background-focus: var(--field-bg);
    --border-color: var(--field-border);
    --border-radius: 9px;
    --border-style: solid;
    --border-width: 1px;
    --color: var(--color-light-black);
    --color-activated: var(--color-light-black);
    --color-focused: var(--color-light-black);
    --color-hover: var(--color-light-black);
    --min-height: 51px;
    --padding-end: 0px;
    --padding-start: 0;
    margin-bottom: 14px;
    --inner-padding-end: 0px;
    font-size: 16px;

    ion-searchbar {
        padding-top: 0;
        padding-bottom: 0;
        -webkit-padding-start: 8px;
        padding-inline-start: 8px;
        -webkit-padding-end: 8px;
        padding-inline-end: 8px;
        --background: transparent;
        --border-radius: 0;
        --box-shadow: none;
        --icon-color: var(--ion-color-primary);
        --color: var(--color-light-black);
        height: 51px;

        .searchbar-search-icon {
            border-right: 1px solid var(--field-border);
            padding-right: 10px;
        }

        input {
            padding-left: 50px !important;
            -webkit-padding-start: 50px;
            padding-inline-start: 50px !important;
        }
    }
}

.label {
    font-size: 16px;
    font-family: L-Semibold !important;
    color: var(--color-black);
    margin-top: 0px;
    margin-bottom: 10px;
}

.input-field {
    --background: var(--field-bg);
    --background-activated: var(--field-bg);
    --background-hover: var(--field-bg);
    --background-focus: var(--field-bg);
    --border-color: var(--field-border);
    --border-radius: 9px;
    --border-style: solid;
    --border-width: 1px;
    --color: var(--color-light-black);
    --color-activated: var(--color-light-black);
    --color-focused: var(--color-light-black);
    --color-hover: var(--color-light-black);
    --min-height: 51px;
    --padding-end: 0px;
    --padding-start: 16px;
    margin-bottom: 14px;
    --inner-padding-end: 0px;
    font-size: 16px;
    font-family: L-Medium !important;

    ion-input {
        border-left: 1px solid var(--field-border);
        --padding-start: 15px !important;
    }

    ion-select {
        border-right: 1px solid var(--field-border);
        // --padding-start: 15px !important;
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        &::part(icon) {
            display: none;
        }
    }

    ion-icon {
        color: var(--ion-color-primary);
        font-size: 22px;
        margin-right: 15px;
    }
}

ion-header {
    ion-toolbar {
        --background: var(--header-bg);

        --box-shadow: none !important;
        --border-style: none;
        --border-width: 0px !important;
        --color: var(--ion-color-text-white);
        --min-height: 60px;
        --padding-start: 16px;
        --padding-end: 16px;

        ion-buttons {
            ion-button {
                --color: var(--ion-color-text-white);
            }
        }

        ion-title {
            font-family: Open-Medium;
            color: var(--ion-color-text-white);
            font-size: 18px;
            text-transform: uppercase;

            .header-logo-style {
                width: 110px;
            }
        }

        ion-back-button {
            --background: teransparent;
            --background-focused: teransparent;
            --border-radius: 0px;
            --color: var(--ion-color-text-white);
        }
    }
}

ion-content {
    --background: var(--color-bg);
}

.primary-btn {
    --background: var(--ion-color-primary);
    --background-activated: var(--ion-color-primary);
    --border-radius: 9px;
    --border-style: none;
    --border-width: 0px;
    --box-shadow: none;
    --color: var(--ion-color-text-white);
    --color-activated: var(--ion-color-text-white);
    font-size: 16px;
    font-family: L-Semibold !important;
    // font-weight: bold;
    text-transform: capitalize;
    height: 50px;
}

.success-btn {
    --background: var(--ion-color-success);
    --background-activated: var(--ion-color-success);
    --border-radius: 9px;
    --border-style: none;
    --border-width: 0px;
    --box-shadow: none;
    --color: var(--ion-color-text-white);
    --color-activated: var(--ion-color-text-white);
    font-size: 16px;
    font-family: L-Semibold !important;
    // font-weight: bold;
    text-transform: capitalize;
    height: 50px;
}

.primary-btn-dark {
    --background: var(--ion-color-secondary);
    --background-activated: var(--ion-color-secondary);
    --border-radius: 9px;
    --border-style: none;
    --border-width: 0px;
    --box-shadow: none;
    --color: var(--ion-color-text-white);
    --color-activated: var(--ion-color-text-white);
    font-size: 16px;
    font-family: L-Semibold !important;
    // font-weight: bold;
    text-transform: capitalize;
    height: 50px;
}

.list-style {
    background: transparent;

    ion-item {
        --background: var(--item-bg);
        --background-hover: var(--item-bg);
        --background-focus: var(--item-bg);
        --background-activated: var(--item-bg);
        --detail-icon-color: var(--ion-color-primary);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
        --detail-icon-opacity: 1;
        --detail-icon-font-size: 28px;
        --inner-padding-end: 0px;
        --padding-start: 16px;
        --padding-end: 10px;
        --padding-top: 5px;
        --padding-bottom: 5px;
        --border-radius: 10px;
        border-radius: 10px;
        margin-bottom: 15px;
        cursor: pointer;
        ion-label {
            h3 {
                font-family: L-Regular !important;
                font-weight: bold;
                font-size: 14px;
                color: var(--color-black);
            }

            p {
                color: var(--medium-black);
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
}

.ion-accordion-toggle-icon {
    color: var(--ion-color-primary);
}

.sticky-section {
    position: sticky;
    top: 0;
    z-index: 10;
}

.inner-html-style {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--color-black) !important;
        font-size: 18px !important;
        font-weight: bold !important;
        margin-top: 10px !important;
        font-family: L-Regular !important;
        margin-bottom: 5px !important;
    }

    p,
    span,
    div,
    a {
        color: var(--color-light-black) !important;
        font-size: 14px !important;
        font-family: L-Light !important;
        font-weight: 300 !important;
        margin-top: 0 !important;
        margin-bottom: 15px !important;
    }
}

.error{
    font-size: 14px;
}

.p2_search {
    --width: 100%;
    --height: 93%;
    backdrop-filter: blur(4px);
}
// .OT_subscriber {
//     height: calc(100vh - 132px) !important;
// }
// #publisher .OT_publisher{
//     width: 150px !important;
//     height: 200px !important;
//     overflow: hidden !important;
// }
.OT_mute{
    display:none !important;
}
.OT_bar{
    display:none !important;
}

.OT_mirrored {
    border-radius: 12px;
}



// PWA css Start

@media screen and (min-width:768px) {
    .pwa-container-size-one{
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    
}
.date_time_item{
    --background: var(--field-bg);
    --background-activated: var(--field-bg);
    --background-hover: var(--field-bg);
    --background-focus: var(--field-bg);
    --border-color: var(--field-border);
    --border-radius: 9px;
    --border-style: solid;
    --border-width: 1px;
    --color: var(--color-light-black);
    --color-activated: var(--color-light-black);
    --color-focused: var(--color-light-black);
    --color-hover: var(--color-light-black);
    --min-height: 51px;
    --padding-end: 0px;
    --padding-start: 0px;
    margin-bottom: 14px;
    --inner-padding-end: 0px;
    font-size: 16px;
    font-family: L-Medium !important;
    position: relative;
    ion-icon{
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        color: var(--ion-color-primary);
        border-left: 1px solid var(--field-border);
        padding-left: 10px;
        height: 70%;
    }
    ion-datetime-button{
        width: 100%;
        height: 100%;
        &::part(native){
            
            width: 100%;
            height: 100%;
            border-radius: 0;
            background: transparent;
            text-align: left;
        }
    }
}
// ion-menu{
//     --width: 304px;
// --min-width: auto;
// --max-width: auto;
// --height: 100%;
// --min-height: auto;
// --max-height: auto;
// --background: var(--ion-background-color, #fff);
// inset: 0px;
// display: none;
// position: absolute;
// contain: strict;
// }
// ion-menu.show-menu{
//     display: block !important;
// }